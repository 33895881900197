@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'ubuntu-latest', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6 !important;

  .ant-image-preview-img {
    display: inline-block;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// antd-table
.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
  color: #bbb;
}

// Aling icon and text in Antd buttion.
// https://github.com/ant-design/ant-design/issues/13074
svg {
  display: inline;
  vertical-align: baseline;
}

// 覆盖 ant-card-hoverable 的样式
.ant-card-hoverable {
  cursor: default !important;
}

.overview {
  min-height: 100vh;
  background: linear-gradient(to bottom, #cde8ff, transparent) repeat-x 0 48px / 100% 194px;

  .company .table .content {
    margin-top: 4.54%;

    @media (min-width: 1900px) {
      margin-top: 33px;
      font-size: 17px;

      .company-name {
        font-size: 16px;
      }
    }
  }
}

.echarts-for-react {
  position: relative;
  overflow: hidden;
}

@primary-color: #1890ff;@border-radius-base: 4px;